import React, { useState } from 'react';
import { SpecialZoomLevel } from '@react-pdf-viewer/core';
import PortalWrp, { PortalId } from '@/Framework/UI/Templates/PortalWrp';
import { useDocumentPreviewContext } from '@/dataroom/application/DocumentPreviewContext';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { DocumentViewer as BaseDocumentViewer } from '@/Framework/UI/Organisms/DocumentViewer';
import { canDownload } from '@/dataroom/domain/filesystemPermissions';
import { canUserBulkDownload, isDefaultAdmin } from '@/dataroom/domain/managePermissions';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import styles from './documentViewer.scss';
import { useDownloadContext } from '@/dataroom/application/DownloadContext';
import { ResponsiveModal as Modal } from '@dealroadshow/uikit';
import PreviewNotAvailable from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/PreviewNotAvailable';
import DataroomRedactionContext, { useDataroomRedactionContext } from '@/dataroom/application/redaction/DataroomRedactionContext';
import * as filesystemPermissions from '@/dataroom/domain/filesystemPermissions';

const DocumentViewer = () => {
  const { tenant } = useDataroomTenantContext();
  const { dataroom } = useDataroomContext();
  const { currentUser } = useCurrentUserContext();
  const { requestDownload, download } = useDownloadContext();
  const {
    previewItem,
    defaultSearch,
    defaultMode,
    resetPreview,
    fileWatermark,
    isFetching,
    isError,
  } = useDocumentPreviewContext();

  const redactionActions = useDataroomRedactionContext();

  const userIsDefaultAdmin = isDefaultAdmin(currentUser);
  const userCanBulkDownload = canUserBulkDownload(currentUser);

  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isRedactionModeVisible, setIsRedactionModeVisible] = useState(false);

  const {
    dataroom: { watermarking, stagingWatermarking, watermarkingOpacity, stagingWatermarkingOpacity },
  } = useDataroomContext();
  const showWatermark = previewItem?.isStaging ? stagingWatermarking : watermarking;
  const opacity = previewItem?.isStaging ? stagingWatermarkingOpacity : watermarkingOpacity;
  const isRedactionEnabled = userIsDefaultAdmin && filesystemPermissions.canRedact(previewItem, 0);

  const handleFinish = () => {
    setIsDownloadLoading(false);
  };

  const handleDownload = () => {
    setIsDownloadLoading(true);

    requestDownload({
      items: [previewItem],
      onFinish: handleFinish,
    }).then(download);
  };

  const handleClose = () => {
    resetPreview();
    setIsRedactionModeVisible(false);
    isRedactionEnabled && redactionActions.listing.resetRedactions();
  };

  if (!previewItem && !isFetching && !isError) return null;

  const documentUrl = isRedactionEnabled && (isRedactionModeVisible || defaultMode === 'redact')
    ? dataroomUrl(tenant).getOriginalPreviewUrl(dataroom.name, previewItem?.id)
    : dataroomUrl(tenant).getPreviewUrl(dataroom.name, previewItem?.id);

  return previewItem.previewable ? (
    <PortalWrp portalId={ PortalId.PORTAL_OVERLAY_ID }>
      <BaseDocumentViewer
        fileId={ previewItem?.id }
        documentTitle={ previewItem?.name }
        defaultSearch={ defaultSearch }
        defaultMode={ defaultMode }
        documentUrl={ documentUrl }
        watermarkText={ showWatermark ? [fileWatermark, 'CONFIDENTIAL INFORMATION - DO NOT DISTRIBUTE'] : [] }
        className={ styles.documentPreviewer }
        close={ handleClose }
        onDownload={ handleDownload }
        isDownloadDisabled={ !canDownload(previewItem, userCanBulkDownload, false, 1) }
        watermarkOpacity={ opacity }
        watermarkWidth={ 480 }
        isFetching={ isFetching }
        isDownloadLoading={ isDownloadLoading }
        isCustomError={ isError }
        defaultScale={ SpecialZoomLevel.ActualSize }
        minZoomLevel={ 50 }
        isBlurProtectionEnabled={ dataroom.blurEnabled }
        isRedactionEnabled={ isRedactionEnabled }
        blurNotification="As a protection measure, your file is hidden when you are not interacting with the viewer. To view this file, move your mouse pointer back to this page."
        redactionActions={ redactionActions }
        setIsRedactionModeVisible={ setIsRedactionModeVisible }
      />
    </PortalWrp>
  ) : (
    <Modal
      title="Preview Not Available"
      isVisible
      className={ styles.previewNotAvailableModal }
      onCloseClicked={ resetPreview }
      dataTest="previewNotAvailableModal"
    >
      { (footerRefCallback) => (
        <PreviewNotAvailable
          item={ previewItem }
          closeModal={ resetPreview }
          footerRefCallback={ footerRefCallback }
        />
        ) }
    </Modal>
  );
};

export default (props) => (
  <DataroomRedactionContext>
    <DocumentViewer { ...props } />
  </DataroomRedactionContext>
);
