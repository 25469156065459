import { useState } from 'react';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { useDIContext } from '@/Framework/DI/DIContext';
import RedactionRepository from '@/dataroom/infrastructure/repository/RedactionRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { DrawingState, ISerializedShape } from '@/Framework/UI/Organisms/DocumentViewer/plugins/RedactionPlugin/types';
import { RedactionStructureType } from '@/dataroom/domain/vo/redaction/RedactionStructureType';
import { IRedactionAction, RedactionAction } from '@/dataroom/domain/vo/redaction/RedactionAction';
import { NotificationManager } from '@/Framework/Notification';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';

const convertShapesToActions = (shapes: ISerializedShape[], oldRedactionsIds: string[]): IRedactionAction[] => {
  const appliedShapesList = shapes.filter((shape) => shape.drawingState === DrawingState.Applied);
  const pendingShapesList = shapes.filter((shape) => shape.drawingState === DrawingState.Pending);

  const redactionsToDelete = oldRedactionsIds
    .reduce((deletedRedactions, redactionId) => {
      if (!appliedShapesList.some((shape) => shape.id === redactionId)) {
        deletedRedactions.push({ redactionId });
      }
      return deletedRedactions;
    }, []);

  const redactionsToAdd = pendingShapesList
    .map((shape, idx) => {
      return ({
        type: RedactionStructureType.Redaction,
        action: RedactionAction.Add,
        data: [{
          type: shape.type,
          label: shape.meta?.selectedText || '',
          entries: [
            {
              entryNumber: idx,
              frames: shape.controlPoints.map(({ x, x1, y, y1, pageIndex }) => ({
                topLeftX: Math.min(x, x1),
                topLeftY: Math.min(y, y1),
                bottomRightX: Math.max(x, x1),
                bottomRightY: Math.max(y, y1),
                pageIndex,
              })),
            },
          ],
    }] });
});

  return [
     ...(redactionsToDelete.length
       ? [{ type: RedactionStructureType.Redaction, action: RedactionAction.Delete, data: redactionsToDelete }]
       : []
     ),
    ...redactionsToAdd,
  ];
};

export default function useEdit() {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const editRedactions = async (fileId: number, shapes: ISerializedShape[], oldRedactionsIds: string[]) => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
        fileId,
        actions: convertShapesToActions(shapes, oldRedactionsIds),
      };

      const redactionRepository = container.get<RedactionRepository>(RedactionRepository);

      await redactionRepository.editRedactions(payload);

      NotificationManager.success(getMessage(messageCodes.DATAROOM_REDACT_FILE_SUCCESS));
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    editRedactions,
  };
}
